var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "mt-3 p-2" }, [
    _c("div", { staticClass: "d-flex justify-content-between" }, [
      _c("h4", { staticClass: "mb-2" }, [_vm._v("Versicherungen")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { disabled: !_vm.isAdmin, type: "button" },
          on: {
            click: function ($event) {
              return _vm.addInsurance()
            },
          },
        },
        [_vm._v("\n      Sparte hinzufügen\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "card card-body shadow-sm my-3" }, [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c("p", { staticClass: "font-weight-bold m-0" }, [_vm._v("Filter")]),
          _vm._v(" "),
          _c("div", { staticClass: "" }, [
            _c(
              "button",
              {
                staticClass: "btn-sm btn",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.showFilter = !_vm.showFilter
                  },
                },
              },
              [
                _vm.showFilter
                  ? _c("font-awesome-icon", { attrs: { icon: "chevron-up" } })
                  : _c("font-awesome-icon", {
                      attrs: { icon: "chevron-down" },
                    }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.showFilter
        ? _c("div", { staticClass: "mt-3" }, [
            _c("div", [
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "d-flex" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter.searchText,
                        expression: "filter.searchText",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "insuranceSearch",
                      "aria-describedby": "insuranceSearchHelp",
                      placeholder: "Sparte suchen",
                      type: "text",
                    },
                    domProps: { value: _vm.filter.searchText },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.searchInsurances()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.filter, "searchText", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary ml-2",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.searchInsurances()
                        },
                      },
                    },
                    [_c("font-awesome-icon", { attrs: { icon: "search" } })],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("p", { staticClass: "" }, [_vm._v("Kategorie")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex" },
                _vm._l(_vm.categories, function (cat) {
                  return _c("div", { key: cat.id, staticClass: "mx-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter.categories,
                          expression: "filter.categories",
                        },
                      ],
                      attrs: { id: cat.id + "_filtercat", type: "checkbox" },
                      domProps: {
                        value: cat,
                        checked: Array.isArray(_vm.filter.categories)
                          ? _vm._i(_vm.filter.categories, cat) > -1
                          : _vm.filter.categories,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.filter.categories,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = cat,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.filter,
                                    "categories",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.filter,
                                    "categories",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.filter, "categories", $$c)
                            }
                          },
                          function ($event) {
                            return _vm.searchInsurances()
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: cat.id + "_filtercat" } }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(cat.description) +
                          "\n            "
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    !_vm.isLoading
      ? _c("section", [
          _c(
            "form",
            {
              attrs: { name: "editForm", novalidate: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveAll()
                },
              },
            },
            [
              _c("div", { staticClass: "p-4" }, [
                !_vm.insurances || _vm.insurances.length === 0
                  ? _c(
                      "div",
                      { staticClass: "mt-3 alert alert-secondary text-center" },
                      [
                        _vm._v(
                          "\n          Es sind keine Sparten im System hinterlegt.\n          "
                        ),
                        _vm.isFilterSet
                          ? [
                              _vm._v(
                                "\n            Bitte ändern Sie die Filtereinstellungen\n            "
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary my-3",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetFilter()
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "times" },
                                  }),
                                  _vm._v(
                                    "\n              Filter zurücksetzen\n            "
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.insurances && _vm.insurances.length !== 0
                  ? _c(
                      "div",
                      _vm._l(_vm.insurances, function (ins, index) {
                        return _c(
                          "div",
                          {
                            key: ins.id,
                            staticClass: "mb-3",
                            staticStyle: { "border-bottom": "2px solid grey" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex mt-3 align-items-start" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group flex-1" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.$v.insurances.$each[index]
                                              .description.$model,
                                          expression:
                                            "$v.insurances.$each[index].description.$model",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        valid:
                                          !_vm.$v.insurances.$each[index]
                                            .description.$invalid,
                                        invalid:
                                          _vm.$v.insurances.$each[index]
                                            .description.$invalid,
                                      },
                                      attrs: {
                                        disabled: !_vm.isAdmin,
                                        placeholder: "Sparte",
                                        type: "text",
                                      },
                                      domProps: {
                                        value:
                                          _vm.$v.insurances.$each[index]
                                            .description.$model,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.$v.insurances.$each[index]
                                              .description,
                                            "$model",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.$v.insurances.$each[index].description
                                      .$anyDirty &&
                                    !_vm.$v.insurances.$each[index].description
                                      .required
                                      ? _c(
                                          "small",
                                          {
                                            staticClass:
                                              "form-text text-danger",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Pflichtfeld\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group ml-2" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.$v.insurances.$each[index]
                                              .category.$model,
                                          expression:
                                            "$v.insurances.$each[index].category.$model",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        valid:
                                          !_vm.$v.insurances.$each[index]
                                            .category.$invalid,
                                        invalid:
                                          _vm.$v.insurances.$each[index]
                                            .category.$invalid,
                                      },
                                      staticStyle: { "min-width": "200px" },
                                      attrs: {
                                        disabled: !_vm.isAdmin,
                                        name: "categorySelect",
                                        required: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.$v.insurances.$each[index]
                                              .category,
                                            "$model",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        { domProps: { value: null } },
                                        [_vm._v("Kategorie")]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(_vm.categories, function (cat) {
                                        return _c(
                                          "option",
                                          {
                                            key: cat.id + "_" + index,
                                            domProps: { value: cat },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(cat.description) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _vm.$v.insurances.$each[index].category
                                    .$anyDirty &&
                                  !_vm.$v.insurances.$each[index].category
                                    .required
                                    ? _c(
                                        "small",
                                        {
                                          staticClass: "form-text text-danger",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Pflichtfeld\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "ml-2 btn btn-primary",
                                    attrs: {
                                      disabled: !_vm.isAdmin,
                                      type: "button",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.prepareRemove(ins)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "trash" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-3" }, [
                              _c("div", { staticClass: "col" }, [
                                _c("div", { staticClass: "form-check" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.$v.insurances.$each[index]
                                            .isRequired.$model,
                                        expression:
                                          "$v.insurances.$each[index].isRequired.$model",
                                      },
                                    ],
                                    staticClass: "form-check-input",
                                    attrs: {
                                      id: "is-required-" + index,
                                      disabled: !_vm.isAdmin,
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.$v.insurances.$each[index]
                                          .isRequired.$model
                                      )
                                        ? _vm._i(
                                            _vm.$v.insurances.$each[index]
                                              .isRequired.$model,
                                            null
                                          ) > -1
                                        : _vm.$v.insurances.$each[index]
                                            .isRequired.$model,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.$v.insurances.$each[index]
                                              .isRequired.$model,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.$v.insurances.$each[index]
                                                  .isRequired,
                                                "$model",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.$v.insurances.$each[index]
                                                  .isRequired,
                                                "$model",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.$v.insurances.$each[index]
                                              .isRequired,
                                            "$model",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-check-label",
                                      attrs: { for: "is-required-" + index },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Pflichtangabe\n                  "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row font-weight-bold" }, [
                              _vm._m(0, true),
                              _vm._v(" "),
                              _vm._m(1, true),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-1 text-center" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "exclamation-triangle" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(ins.options, function (opt, optionIndex) {
                              return _c(
                                "div",
                                { key: "ins_" + index + "_opt_" + optionIndex },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "col-2 text-center font-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              opt.percentage != null
                                                ? opt.percentage.value
                                                : "###"
                                            ) +
                                            " %\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-9" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.$v.insurances.$each[index]
                                                  .options.$each[optionIndex]
                                                  .description.$model,
                                              expression:
                                                "\n                      $v.insurances.$each[index].options.$each[optionIndex].description.$model\n                    ",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: {
                                            valid:
                                              !_vm.$v.insurances.$each[index]
                                                .options.$each[optionIndex]
                                                .description.$invalid,
                                            invalid:
                                              _vm.$v.insurances.$each[index]
                                                .options.$each[optionIndex]
                                                .description.$invalid,
                                          },
                                          attrs: {
                                            disabled: !_vm.isAdmin,
                                            placeholder: "Beschreibung",
                                            type: "text",
                                          },
                                          domProps: {
                                            value:
                                              _vm.$v.insurances.$each[index]
                                                .options.$each[optionIndex]
                                                .description.$model,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.$v.insurances.$each[index]
                                                  .options.$each[optionIndex]
                                                  .description,
                                                "$model",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-1 d-flex justify-content-center align-items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.$v.insurances.$each[
                                                      index
                                                    ].options.$each[optionIndex]
                                                      .warning.$model,
                                                  expression:
                                                    "\n                        $v.insurances.$each[index].options.$each[optionIndex].warning.$model\n                      ",
                                                },
                                              ],
                                              staticClass: "",
                                              class: {
                                                valid:
                                                  !_vm.$v.insurances.$each[
                                                    index
                                                  ].options.$each[optionIndex]
                                                    .warning.$invalid,
                                                invalid:
                                                  _vm.$v.insurances.$each[index]
                                                    .options.$each[optionIndex]
                                                    .warning.$invalid,
                                              },
                                              attrs: {
                                                disabled: !_vm.isAdmin,
                                                placeholder: "Warnung",
                                                type: "checkbox",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.$v.insurances.$each[index]
                                                    .options.$each[optionIndex]
                                                    .warning.$model
                                                )
                                                  ? _vm._i(
                                                      _vm.$v.insurances.$each[
                                                        index
                                                      ].options.$each[
                                                        optionIndex
                                                      ].warning.$model,
                                                      null
                                                    ) > -1
                                                  : _vm.$v.insurances.$each[
                                                      index
                                                    ].options.$each[optionIndex]
                                                      .warning.$model,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.$v.insurances.$each[
                                                        index
                                                      ].options.$each[
                                                        optionIndex
                                                      ].warning.$model,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.$v.insurances
                                                            .$each[index]
                                                            .options.$each[
                                                            optionIndex
                                                          ].warning,
                                                          "$model",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.$v.insurances
                                                            .$each[index]
                                                            .options.$each[
                                                            optionIndex
                                                          ].warning,
                                                          "$model",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.$v.insurances.$each[
                                                        index
                                                      ].options.$each[
                                                        optionIndex
                                                      ].warning,
                                                      "$model",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  opt.warning
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "row border-bottom mb-3",
                                        },
                                        [
                                          _c("div", { staticClass: "col-2" }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "form-group col-9" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.$v.insurances.$each[
                                                        index
                                                      ].options.$each[
                                                        optionIndex
                                                      ].defaultComment.$model,
                                                    expression:
                                                      "\n                      $v.insurances.$each[index].options.$each[optionIndex].defaultComment.$model\n                    ",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: {
                                                  valid:
                                                    !_vm.$v.insurances.$each[
                                                      index
                                                    ].options.$each[optionIndex]
                                                      .defaultComment.$invalid,
                                                  invalid:
                                                    _vm.$v.insurances.$each[
                                                      index
                                                    ].options.$each[optionIndex]
                                                      .defaultComment.$invalid,
                                                },
                                                attrs: {
                                                  placeholder:
                                                    "Anmerkung für den Bericht",
                                                  type: "text",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.$v.insurances.$each[
                                                      index
                                                    ].options.$each[optionIndex]
                                                      .defaultComment.$model,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.$v.insurances.$each[
                                                        index
                                                      ].options.$each[
                                                        optionIndex
                                                      ].defaultComment,
                                                      "$model",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "small",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Anmerkung")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "text-center" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { disabled: !_vm.isAdmin, type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.addInsurance()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "plus" } }),
                      _vm._v("  Sparte hinzufügen\n          "),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "action-wrapper text-right mt-3" }, [
                _c("div", [
                  _vm.$v.insurances.$anyDirty
                    ? _c(
                        "button",
                        {
                          staticClass: "btn",
                          attrs: { id: "cancel-save", type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.cancel()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "ban" } }),
                          _vm._v("  Abbrechen\n          "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        id: "save-entity",
                        disabled:
                          !_vm.$v.insurances.$anyDirty ||
                          _vm.$v.insurances.$invalid ||
                          _vm.isSaving,
                        type: "submit",
                      },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "save" } }),
                      _vm._v("  Speichern\n          "),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.$v.insurances.$invalid
                  ? _c("div", { staticClass: "text-muted mt-3" }, [
                      _c(
                        "small",
                        { staticClass: "text-muted font-weight-bold" },
                        [
                          _vm._v(
                            "\n            Bitte beheben Sie alle Fehler um die Einträge zu speichern.\n          "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "b-modal",
                { ref: "removeEntity", attrs: { id: "removeEntity" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "modal-title" }, slot: "modal-title" },
                    [
                      _c(
                        "span",
                        {
                          attrs: {
                            id: "cdpRisikoApp.percentage.delete.question",
                            "data-cy": "percentageDeleteDialogHeading",
                          },
                        },
                        [_vm._v("Löschen bestätigen")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body" }, [
                    _c(
                      "p",
                      { attrs: { id: "gen-delete-percentage-heading" } },
                      [
                        _vm._v(
                          "\n            Sind Sie sicher, dass Sie diesen Eintrag\n            "
                        ),
                        _vm.entityToRemove
                          ? _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(_vm.entityToRemove.description) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" löschen möchten?\n          "),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog()
                            },
                          },
                        },
                        [_vm._v("Nicht löschen")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            id: "gen-confirm-delete-percentage",
                            "data-cy": "entityConfirmDeleteButton",
                            type: "button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removeInsurance()
                            },
                          },
                        },
                        [_vm._v("\n            Löschen\n          ")]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isLoading
      ? _c(
          "section",
          [
            _vm.isLoading
              ? _c("font-awesome-icon", {
                  staticClass: "m-3",
                  attrs: { spin: true, icon: "sync" },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-2 text-center" }, [
      _c("p", [_vm._v("%")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-9" }, [
      _c("p", [_vm._v("Beschreibung")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }