
import { Component, Inject, Vue } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import AlertService from '@/shared/alert/alert.service';
import PercentageService from '@/entities/percentage/percentage.service';
import { required } from 'vuelidate/lib/validators';
import { IInsurance, Insurance } from '@/shared/model/insurance.model';
import InsuranceService from '@/entities/insurance/insurance.service';
import CategoryService from '@/entities/category/category.service';
import { ICategory } from '@/shared/model/category.model';
import { IPercentage } from '@/shared/model/percentage.model';
import { InsuranceOption } from '@/shared/model/insurance-option.model';
import AuthService from '@/account/auth.service';

const validations: any = {
  insurances: {
    $each: {
      description: {
        required,
      },
      category: {
        required,
      },
      isRequired: {},
      options: {
        $each: {
          description: {},
          warning: {},
          defaultComment: {},
        },
      },
    },
  },
};
@Component({
  mixins: [Vue2Filters.mixin],
  validations,
})
export default class InsuranceSettings extends Vue {
  //all insurances, used for fallbacks and to apply the filter against, etc.
  public allInsurances: IInsurance[] = [];

  public categoryLoading = false;
  public percentageLoading = false;
  public insuranceLoading = false;
  public isSaving = false;
  public showFilter = false;
  public filter: {
    searchText: string;
    categories: ICategory[];
  } = {
    searchText: '',
    categories: [],
  };

  //remove dialog fields
  public entityToRemove: IInsurance = null;
  //all categories used to init the category select
  public categories: ICategory[] = [];
  @Inject('insuranceService') private insuranceService: () => InsuranceService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('categoryService') private categoryService: () => CategoryService;
  @Inject('percentageService') private percentageService: () => PercentageService;
  public isAdmin = false;
  //authService
  @Inject('authService') private authService: () => AuthService;
  //all percentages used to fill missing percentages in insurances and to init new insurances
  private percentages: IPercentage[] = [];

  get isLoading() {
    return this.categoryLoading || this.percentageLoading || this.insuranceLoading;
  }

  get insurances() {
    return this.searchInsurances();
  }

  get isFilterSet() {
    return this.filter.searchText.length > 0 || this.filter.categories.length > 0;
  }

  public mounted(): void {
    this.init();
  }

  /**
   * Saves all insurances to the server.
   * @returns {Promise<void>}
   */
  async saveAll(): Promise<void> {
    const insurancesToSave = this.allInsurances;
    try {
      if (this.isSaving) {
        return;
      }
      if (insurancesToSave == null || insurancesToSave.length === 0) {
        this.alertService().showError(this, 'Bitte fügen Sie mindestens eine Versicherung hinzu');
        return;
      }
      this.isSaving = true;
      //update and save all insurances
      this.allInsurances = await this.insuranceService().saveAll(insurancesToSave);
      this.alertService().showSuccess(this, 'Versicherungen erfolgreich gespeichert');
    } catch (e) {
      let msg = 'Fehler beim Speichern der Versicherungen';
      //log error
      if (e?.response?.data?.title) {
        msg = e.response.data.title;
      }
      console.log(e);
      this.alertService().showError(this, msg);
    } finally {
      this.isSaving = false;
    }
  }

  /**
   * resets the filter
   */
  public resetFilter() {
    this.filter = { searchText: '', categories: [] };
  }

  /**
   * Initializes the component by retrieving all categories, percentages, and insurances.
   * @returns {Promise<void>}
   */
  async init(): Promise<void> {
    this.retrieveAllCategories().then();
    await this.retrieveAllPercentages();
    await this.retrieveAllInsurances();
    this.isAdmin = await this.authService().isAdmin();
  }

  /**
   * Retrieves all insurances and sorts them by category and description.
   * Adds missing percentages to each insurance and sorts all options by percentage value.
   * @returns {Promise<void>}
   */
  public async retrieveAllInsurances(): Promise<void> {
    try {
      this.insuranceLoading = true;

      const paginationQuery = {
        page: 0,
        size: 1000,
        sort: ['category.orderNumber', 'description'],
      };
      paginationQuery.sort = [];

      const res = await this.insuranceService().retrieve(paginationQuery);
      const insurances: IInsurance[] = res.data;

      // Sort insurances by category and description
      insurances.sort((a, b) => this.sortInsurances(a, b));

      // Add missing percentages to each insurance
      insurances.forEach(ins => this.addMissingPercentages(ins));

      // Sort all options by percentage value desc
      insurances.forEach(ins => this.sortInsuranceOptions(ins));

      // Set allInsurances property
      this.allInsurances = insurances;
    } catch (e) {
      // Show error message if retrieving insurances fails
      this.alertService().showError(this, 'Fehler beim Laden der Versicherungen');
      console.log(e);
    } finally {
      // Set insuranceLoading to false when done
      this.insuranceLoading = false;
    }
  }

  public prepareRemove(instance: IInsurance): void {
    this.entityToRemove = instance;
    if (this.$refs.removeEntity as any) {
      (this.$refs.removeEntity as any).show();
    }
  }

  /**
   * Remove an insurance by id.
   */
  public async removeInsurance() {
    try {
      //remove from list if not saved
      if (this.entityToRemove.id == null) {
        this.allInsurances = this.allInsurances.filter(ins => ins !== this.entityToRemove);
        this.alertService().showInfo(this, 'Der Eintrag wurde erfolgreich gelöscht');
        this.closeDialog();
        return;
      }

      await this.insuranceService().delete(this.entityToRemove.id);
      this.alertService().showInfo(this, 'Der Eintrag wurde erfolgreich gelöscht');
      this.entityToRemove.id = null;
      this.retrieveAllInsurances();
      this.closeDialog();
    } catch (e) {
      this.alertService().showError(this, 'Der Eintrag konnte nicht gelöscht werden');
    }
  }

  public closeDialog(): void {
    (this.$refs.removeEntity as any).hide();
  }

  /**
   * adds new insurance to list
   */
  public addInsurance(): void {
    //scroll to bottom (where new insurance will be added)
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });

    const newIns = new Insurance();
    newIns.category = null;

    const opts: InsuranceOption[] = [];
    //add all percentages
    this.percentages.forEach(item => {
      const tempOption = new InsuranceOption();
      tempOption.percentage = item;
      opts.push(tempOption);
    });

    newIns.options = opts;
    //sort to meet standard
    this.sortInsuranceOptions(newIns);
    this.allInsurances.push(newIns);
  }

  /**
   * search insurances by text and category action
   *
   */
  searchInsurances(): IInsurance[] {
    let filtedInsurances = this.allInsurances;
    //filter by text

    filtedInsurances = filtedInsurances.filter(ins => {
      //check by text
      let foundByText = false;
      if (this.filter.searchText.length > 0) {
        foundByText = ins.description.toLowerCase().includes(this.filter.searchText.toLowerCase());
      } else {
        //if no text is set, return all
        foundByText = true;
      }

      //check by category
      let foundByCategory = false;
      if (this.filter.categories.length > 0) {
        //map to ids
        const categoryIds = this.filter.categories.map(cat => cat.id);
        //check if id is in list
        foundByCategory = categoryIds.includes(ins.category.id);
      } else {
        //if no category is set, return all
        foundByCategory = true;
      }
      // return if both are true
      return foundByText && foundByCategory;
    });

    //sort
    filtedInsurances.sort((a, b) => this.sortInsurances(a, b));
    return filtedInsurances;
  }

  /**
   * cancels the edit and reloads the page
   */
  public cancel(): void {
    //reload page
    window.location.reload();
  }

  /**
   * Adds missing percentages to the given insurance.
   * @param {Insurance} ins - The insurance to add missing percentages to.
   */
  private addMissingPercentages(ins: Insurance): void {
    const missingPercentages = this.percentages.filter(
      perc => !ins.options.map(opt => opt.percentage.id).includes(perc.id)
    );
    missingPercentages.forEach(perc => {
      const tempOption = new InsuranceOption();
      tempOption.percentage = perc;
      ins.options.push(tempOption);
    });
  }

  /**
   * Retrieves all categories from the server used to init related fields
   * @private
   */
  private async retrieveAllCategories() {
    try {
      this.categoryLoading = true;
      const cats = (await this.categoryService().retrieve({ sort: ['orderNumber'] })).data;
      //remove all user properties
      cats.forEach(cat => {
        delete cat.user;
      });
      this.categories = cats;
    } catch (e) {
      this.alertService().showError(this, 'Fehler beim Laden der Kategorien');
    } finally {
      this.categoryLoading = false;
    }
  }

  /**
   * Retrieves all percentages from the server, used to init related fields
   * @private
   */
  private async retrieveAllPercentages() {
    try {
      this.percentageLoading = true;
      this.percentages = (await this.percentageService().retrieve({ sort: ['value'] })).data;
    } catch (e) {
      this.alertService().showError(this, 'Fehler beim Laden der Prozentwerte');
    } finally {
      this.percentageLoading = false;
    }
  }

  /**
   * Sorts insurances by category and description
   * @param a first insurance
   * @param b second insurance
   * @private private
   */
  private sortInsurances(a: IInsurance, b: IInsurance): number {
    //if both a new (id==null) dont sort
    if (a.id == null && b.id == null) {
      return 0;
    }
    //those with no category are always last
    if (a.id == null || a.category.id == null) {
      return 1;
    }
    if (b.id == null || b.category.id == null) {
      return -1;
    }

    if (a.category.id === b.category.id) {
      return a.description.localeCompare(b.description);
    }
    //sort by category order number
    return a.category.orderNumber - b.category.orderNumber;
  }

  /**
   * Sorts the options of the given insurance by percentage value in descending order.
   * @param {Insurance} ins - The insurance whose options to sort.
   */
  private sortInsuranceOptions(ins: Insurance): void {
    ins.options.sort((a, b) => b.percentage.value - a.percentage.value);
  }
}
